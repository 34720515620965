/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@2.2.4/dist/jquery.min.js
 * - /npm/popper.js@1.14.4/dist/umd/popper.min.js
 * - /npm/bootstrap@4.1.3/dist/js/bootstrap.min.js
 * - /npm/@fortawesome/fontawesome-free@5.6.3/js/all.min.js
 * - /gh/posabsolute/jQuery-Validation-Engine@2.6.4/js/languages/jquery.validationEngine-ja.min.js
 * - /gh/posabsolute/jQuery-Validation-Engine@2.6.4/js/jquery.validationEngine.min.js
 * - /npm/flatpickr_plus@1.1.20/dist/flatpickr.min.js
 * - /npm/flatpickr_plus@1.1.20/dist/l10n/ja.min.js
 * - /npm/flatpickr_plus@1.1.20/dist/plugins/yearDropdown/index.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
